<template>
  <v-dialog v-model="value" max-width="610px" persistent>
    <v-card class="request-email-confirmation-content">
      <v-card-title>
        <span class="title">Confirmação de e-mail</span>
      </v-card-title>
      <v-card-text>
        <div>
          <v-row>
            <v-col>
              <span class="subtitle"
                >Temos um login novo e mais seguro, agora todos os nossos usuários precisam confirmar o e-mail usado como login. Para isso você receberá um link
                no endereço informado abaixo para confirmar que esse e-mail é seu mesmo.</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="newEmail" :append-icon="emailIcon" :error-messages="emailError" label="E-mail" outlined />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <mf-action-buttons
        class="mt-n6 mx-2 pb-2"
        :primary-button="{
          text: 'Confirmar E-mail',
          action: confirmEmail,
          isVisible: true,
          isDisabled: loadingEmail || !newEmail.length,
          isLoading: loading,
        }"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { QUERY_CHECK_AVAILABLE_EMAIL, MUTATION_REQUEST_EMAIL_CONFIRMATION } from '@/modules/login/graphql'

export default {
  name: 'RequestEmailConfirmation',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    currentEmail: {
      type: String,
    },
    password: {
      type: String,
    },
  },
  data: () => ({
    newEmail: '',
    loadingEmail: false,
    availableEmail: true,
    loading: false,
  }),
  computed: {
    emailIcon() {
      if (!this.newEmail) return ''
      if (this.loadingEmail) return 'fas fa-circle-notch fa-spin'
      if (this.availableEmail) return 'fas fa fa-check'
      return ''
    },
    emailError() {
      if (this.v$.newEmail?.$error) {
        if (this.v$.newEmail.required.$invalid) return 'Insira um e-mail.'
        if (this.v$.newEmail.email.$invalid) return 'Insira um e-mail válido.'
      }
      if (!this.availableEmail && !this.loadingEmail) return 'E-mail não disponível.'
      return ''
    },
  },
  watch: {
    currentEmail() {
      this.newEmail = this.currentEmail
    },
  },
  methods: {
    async validateEmail() {
      if (this.currentEmail === this.newEmail) {
        this.availableEmail = true
        return
      }
      if (this.v$.newEmail?.$error) {
        this.availableEmail = false
        return
      }
      this.loadingEmail = true
      this.availableEmail = false
      try {
        const { data } = await this.$apollo.query({
          query: QUERY_CHECK_AVAILABLE_EMAIL,
          variables: {
            email: this.newEmail,
          },
          fetchPolicy: 'no-cache',
        })
        if (data.emailIsAvailable.result) {
          this.availableEmail = true
        } else {
          this.$snackbar({ message: 'E-mail não está disponível para uso', snackbarColor: 'error' })
        }
      } catch (e) {
        console.error(e)
        this.$snackbar({ message: 'Falha ao validar e-mail', snackbarColor: 'error' })
      }
      this.loadingEmail = false
    },
    async confirmEmail() {
      // If the email is being validated, the save button should do nothing
      this.v$.$reset()
      this.v$.$touch()
      if (this.v$?.$error) {
        return
      }

      this.loading = true
      await this.validateEmail()
      if (!this.availableEmail) {
        this.loading = false
        return
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REQUEST_EMAIL_CONFIRMATION,
          fetchPolicy: 'no-cache',
          variables: {
            email: this.newEmail,
            password: this.password,
          },
        })
        this.loading = false
        Swal.fire({
          html: `<span class="subtitle">Sucesso, enviamos um e-mail para <strong>${this.newEmail}</strong>, confira sua caixa de entrada para confirmar este endereço.</span>
          <br /><br />
          <span class="subtitle">Caso não tenha recebido o e-mail, tente procurar na sua caixa de spam. Se ele não estiver lá, entre em contato com o administrador da sua plataforma e solicite a troca do seu endereço de e-mail.</span>
          <br /><br />
          <span class="subtitle">Em último caso entre em contato com: suporte@mercafacil.com</span>
          `,
          icon: 'success',
          showConfirmButton: false,
          allowOutsideClick: false,
          customClass: {
            content: 'content-class',
          },
        })
        this.$emit('input', false)
      } catch (e) {
        console.error(e)
        this.$snackbar({ message: 'Falha ao solicitar confirmação de e-mail.', snackbarColor: 'error' })
        this.loading = false
      }
    },
  },
  validations() {
    return {
      newEmail: { email, required },
    }
  },
  setup() {
    return { v$: useVuelidate('', '', { $scope: false }) }
  },
}
</script>

<style lang="scss">
.request-email-confirmation-content {
  height: 300px;
}
</style>
